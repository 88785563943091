/**
 * Copyright Compunetix Incorporated 2017-2024
 *         All rights reserved
 * This document and all information and ideas contained within are the
 * property of Compunetix Incorporated and are confidential.
 *
 * Neither this document nor any part nor any information contained in it may
 * be disclosed or furnished to others without the prior written consent of:
 *         Compunetix Incorporated
 *         2420 Mosside Blvd
 *         Monroeville, PA 15146
 *         http://www.compunetix.com
 *
 * Author:  lcheng, kbender
 */
import {
  Component,
  Input,
  AfterViewInit,
  ElementRef,
  ViewChildren,
  QueryList,
  Output,
  EventEmitter,
  OnInit,
  SimpleChanges,
  OnChanges
} from "@angular/core";
import { RestService } from "../shared/services/rest.service";
import { FormGroup, FormArray, FormControl, AbstractControl } from "@angular/forms";
import {
  IParameter,
  ParameterType,
  AlertLevel,
  ThirdPartyAuthProtocol,
  ThirdPartyAuthProvider,
  Group
} from "companion";
import { AlertService } from "../alert/alert.service";
import { TreeViewItem } from "./../shared/components/tree-view/tree-item";
import { Dispatcher, ActionType } from "../shared/services/dispatcher";

@Component({
  selector: "form-field",
  templateUrl: "./field.template.html"
})

/**
 * form field
 */
export class FieldComponent implements OnInit, AfterViewInit, OnChanges {

  /**
   * current field parameter
   */
  @Input() parameter: IParameter;

  /**
   * the form
   */
  @Input() form: FormGroup;

  /**
   * form control key
   */
  @Input() controlKey: string;

  /**
   * form section key
   */
  @Input() sectionKey: string;

  /**
   * flag if editing form
   */
  @Input() isEditing: boolean = false;
  /**
   * flag if editing form
   */
  @Input() togglePasswords: boolean;

  @Input() unselectAvailable: boolean = false;

  @Input() multiSelectAvailable: boolean = false;
  
  @Input() deleteItemAvailable: boolean = false;

  @Input() levelSelectionAvailable: boolean = false;

  /**
   * change event emitter
   */
  @Output("valueChanged") changeEventEmitter: EventEmitter<string> = new EventEmitter<string>();

  /**
   * change event emitter
   */
  @Output("lockableEnabled") lockableEnabledEmitter: EventEmitter<boolean> = new EventEmitter<boolean>();

  /**
   * check if this field is valid
   */
  get isValid() {
    return !this.parameter.errorMessage && this.form.controls[this.controlKey].valid;
  }

  /**
   * check if section enabled
   */
  get isSectionEnabled() {
    return this.form.value[this.sectionKey + "_enabled"] || !this.form.controls[this.sectionKey + "_enabled"];
  }

  /**
   * check if control is section toggler
   */
  get isSectionToggler() {
    return this.controlKey === this.sectionKey + "_enabled";
  }

  get isFocusable() {
    return _.includes(
      [
        ParameterType[ParameterType.string],
        ParameterType[ParameterType.number],
        ParameterType[ParameterType.email],
        ParameterType[ParameterType.date],
        ParameterType[ParameterType.time],
        ParameterType[ParameterType.phone]
      ],
      this.parameter.type
    );
  }

  get errorMessage(): string {
    let result: string = this.parameter.title + " is invalid.";
    if (this.parameter.errorMessage) {
      result = this.parameter.errorMessage;
    } else if (this.form.controls[this.controlKey] && this.form.controls[this.controlKey].errors) {
      if (this.form.controls[this.controlKey].errors.required) {
        result = this.parameter.title + " is required.";
      } else if (this.form.controls[this.controlKey].errors.maxlength) {
        result = this.parameter.title + " cannot be longer than " + this.parameter.maxLength + " characters.";
      } else if (this.form.controls[this.controlKey].errors.email) {
        result = this.parameter.title + " must be a valid email address.";
      }
    }
    return result;
  }

  wysiwygModel: any = {
    id: "wysiwyg-uninit",
    wysiwygContent: "",
    editorTheme: "snow",
    editorModules: {
      toolbar: [
        [{ header: [1, 2, 3, 4, 5, 6, false] }],
        [{ "size": ["small", false, "large", "huge"] }],
        [{ "font": [] }],
        ["bold", "italic", "underline"],
        [{ list: "ordered" }, { list: "bullet" }],
        [{ align: [] }],
        [{ indent: "-1" }, { indent: "+1" }],
        ["link", "image"]
      ]
    }
  };

  @ViewChildren("InputField") inputFields: QueryList<ElementRef>;

  /**
   * export enum to template
   */
  public ThirdPartyAuthProtocol = ThirdPartyAuthProtocol;
  public ThirdPartyAuthProvider = ThirdPartyAuthProvider;
  public Group = Group;
  public cpModeOptions = Array(20)
    .fill(1)
    .map((v: any, i: number) => v + i);
  public passwordFieldEnabled = true;

  constructor(
    private restService: RestService,
    private alertService: AlertService,
  ) {}

  /**
   * init here
   */
  ngOnInit(): void {
    this.wysiwygModel.id = "wysiwyg-" + this.controlKey;
  }

  ngOnChanges(changes: SimpleChanges) {
    const parameter = changes["parameter"];
    const togglePasswords = changes["togglePasswords"];
    if (parameter && parameter.currentValue.lockable) {
      this.togglePasswordField(!this.isEditing);
    }
    if (togglePasswords && togglePasswords.previousValue !== togglePasswords.currentValue && this.parameter.lockable) {
      this.togglePasswordField(togglePasswords.currentValue);
    }
  }

  ngAfterViewInit() {
    if (this.parameter && this.parameter.type === ParameterType[ParameterType.tree] && this.parameter.value) {
      this.markSelectedIfMatch(this.parameter.value, this.parameter.options[0]);
    }
  }

  /**
   * upload image to server
   */
  uploadImage(event: any) {
    this.valueChanged(event);
    let fileList: FileList = event.target.files;
    if (fileList.length > 0) {
      let file: File = fileList[0];
      let formData: FormData = new FormData();
      formData.append("image", file, file.name);
      this.restService
        .upload("/uploadImage", formData)
        .subscribe(
          (data: any) => {
            this.form.controls[this.controlKey].setValue(data.filePath);
          },
          (error: any) => {
            this.alertService.createAlert("UPLOAD_IMAGE_FAILED", "Failed to upload the image.", AlertLevel.error);
          }
        );
    }
  }

  /**
   * remove image from field
   */
  removeImage() {
    this.form.controls[this.controlKey].setValue(this.parameter.valueOnDelete);
  }

  /**
   * upload video to server
   */
  uploadVideo(event: any) {
    this.valueChanged(event);
    let fileList: FileList = event.target.files;
    if (fileList.length > 0) {
      let file: File = fileList[0];
      let formData: FormData = new FormData();
      formData.append("video", file, file.name);
      this.restService
        .upload("/uploadVideo", formData)
        .subscribe(
          (data: any) => {
            this.form.controls[this.controlKey].setValue(data.filePath);
          },
          (error: any) => {
            this.alertService.createAlert("UPLOAD_VIDEO_FAILED", "Failed to upload the video.", AlertLevel.error);
          }
        );
    }
  }

  /**
   * remove video from field
   */
  removeVideo() {
    this.form.controls[this.controlKey].setValue(this.parameter.valueOnDelete);
  }

  uploadThemePackage(event: any) {
    let fileList: FileList = event.target.files;
    if (fileList.length > 0) {
      let file: File = fileList[0];
      let formData: FormData = new FormData();
      formData.append("theme", file, file.name);
      this.restService
        .upload("/uploadThemePackage", formData)
        .subscribe(
          (data: any) => {
            this.form.controls[this.controlKey].setValue(data.filePath);
            let themesControl: FormArray = this.form.controls["group_themes"] as FormArray;
            if (themesControl) {
              let themeKeys = _.map(themesControl.controls, "value.key");
              let themeValue = themesControl.value;
              if (!_.includes(themeKeys, data.themeName)) {
                let keyValueControls: { [key: string]: AbstractControl } = {};
                keyValueControls["key"] = new FormControl(data.themeName);
                keyValueControls["value"] = new FormControl(true);
                (themesControl as FormArray).controls.push(new FormGroup(keyValueControls));
                themeValue.push({ key: data.themeName, value: true });
              } else {
                let matchedTheme: any = _.find(themeValue, { key: data.themeName });
                matchedTheme.value = true;
              }
              themesControl.setValue(themeValue);
            }
          },
          (error: any) => {
            this.alertService.createAlert(
              "UPLOAD_THEME_PACKAGE_FAILED",
              "Failed to upload the theme package.",
              AlertLevel.warning
            );
          }
        );
    }
  }

  /**
   * add key value control
   */
  addKeyValueControl() {
    (this.form.controls[this.controlKey] as FormArray).push(
      new FormGroup({
        key: new FormControl(""),
        value: new FormControl("")
      })
    );
  }

  /**
   * add list item control
   */
  addListItemControl() {
    (this.form.controls[this.controlKey] as FormArray).push(new FormControl(""));
  }

  /**
   * add account control
   */
  addAccountControl() {
    (this.form.controls[this.controlKey] as FormArray).push(
      new FormGroup({
        host: new FormControl(""),
        username: new FormControl(""),
        password: new FormControl(""),
        authSecret: new FormControl("")
      })
    );
  }

  /**
   * add account control
   */
  addRemoteDesktopControl() {
    (this.form.controls[this.controlKey] as FormArray).push(
      new FormGroup({
        remoteIdentity: new FormControl(""),
        hostname: new FormControl(""),
        port: new FormControl(""),
        username: new FormControl(""),
        password: new FormControl(""),
      })
    );
  }

  /**
   * add third party auth control
   */
  addThirdPartyAuthControl() {
    (this.form.controls[this.controlKey] as FormArray).push(
      new FormGroup({
        protocol: new FormControl(""),
        provider: new FormControl(""),
        clientID: new FormControl(""),
        authorizationUrl: new FormControl(""),
        issuer: new FormControl(""),
        tokenUrl: new FormControl(""),
        // loginUrl: new FormControl(""),
        // logoutUrl: new FormControl(""),
        // cert: new FormControl(""),
        // signatureAlgorithm: new FormControl(""),
        secret: new FormControl(""),
        defaultUrl: new FormControl("")
      })
    );
  }

  /**
   * add cpmode control
   */
  addCPModeControl() {
    (this.form.controls[this.controlKey] as FormArray).push(
      new FormGroup({
        mode: new FormControl(),
        submode: new FormControl(1)
      })
    );
  }

  /**
   * add size control
   */
  addSizeControl() {
    (this.form.controls[this.controlKey] as FormArray).push(
      new FormGroup({
        width: new FormControl(""),
        height: new FormControl("")
      })
    );
  }

  /**
   * add queue control
   */
  addQueueControl() {
    (this.form.controls[this.controlKey] as FormArray).push(
      new FormGroup({
        langCode: new FormControl(""),
        languageText: new FormControl(""),
        categoryText: new FormControl(""),
        startButtonText: new FormControl(""),
        queueCat: new FormControl(""),
        queueLang: new FormControl("")
      })
    );
  }

  /**
   * remove list item
   */
  removeListItem(index: number) {
    (this.form.controls[this.controlKey] as FormArray).removeAt(index);
  }

  /**
   * handler color picker color change event
   */
  colorPickerChanged(color: string) {
    this.form.controls[this.controlKey].setValue(color);
  }

  /**
   * tree view select event
   * @param item - tree view item
   */
  treeSelect(item: TreeViewItem, unselectAvailable: boolean = false, multiSelectAvailable: boolean = false) {
    this.valueChanged(item);
    this.form.controls[this.controlKey].setValue(item.selected ? item.value : null);
  }

  /**
   * mark root
   * @param value selected item value
   * @param root root
   */
  markSelectedIfMatch(value: any, root: TreeViewItem) {
    root.selected = root.value === value;
    if (root.children) {
      _.forEach(root.children, (child: TreeViewItem) => {
        this.markSelectedIfMatch(value, child);
      });
    }
  }

  focus() {
    let setFocusTimer = setTimeout(() => {
      clearTimeout(setFocusTimer);
      if (this.inputFields && this.inputFields.length > 0) {

        this.inputFields.first.nativeElement.focus();
      }
    }, 500);
  }

  valueChanged(event: any) {
    if (event) {
      let checkCPMode = /video_layout_view_profile_mode_(\d)/.exec(event.srcElement ? event.srcElement.id : "");
      if (checkCPMode) {
        ((this.form.controls[this.controlKey] as FormArray).controls[parseInt(checkCPMode[1], 10)] as FormGroup).controls[
          "submode"
        ].setValue("1");
      }
    }
    this.changeEventEmitter.emit(this.parameter.key);
  }

  enablePasswordField(enabled: boolean) {
    this.passwordFieldEnabled = enabled;
    const enablerTimer = setTimeout(() => {
      clearTimeout(enablerTimer);
      if (enabled) {
        this.form.controls[this.controlKey].enable();
        this.lockableEnabledEmitter.emit(true);
      } else {
        this.form.controls[this.controlKey].disable();
        this.lockableEnabledEmitter.emit(false);
      }
    }, 100);
  }

  togglePasswordField(enabled: boolean) {
    this.passwordFieldEnabled = enabled;
    const enablerTimer = setTimeout(() => {
      clearTimeout(enablerTimer);
      if (enabled) {
        this.form.controls[this.controlKey].enable();
      } else {
        this.form.controls[this.controlKey].disable();
      }
    }, 100);
  }

  addCategorySubskill() {
    if (this.form.controls["skill_set_categories_subskills_new"].value != "") {
      // add only if non empty
      Dispatcher.dispatch(ActionType.AddCategorySubskill, { newCategorySubskillName: this.form.controls["skill_set_categories_subskills_new"].value, parentCategorySubskillName: this.form.controls["skill_set_categories_subskills"].value?.name });
      // clear after adding
      this.form.controls["skill_set_categories_subskills_new"].setValue("");
    }
  }

  removeTreeViewItem(item: string) {
    this.form.controls[this.controlKey].setValue(null);
  }

  levelSelectionChangedTreeViewItem(item: TreeViewItem) {
  }

}
