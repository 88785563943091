/**
 * Copyright Compunetix Incorporated 2017
 *         All rights reserved
 * This document and all information and ideas contained within are the
 * property of Compunetix Incorporated and are confidential.
 *
 * Neither this document nor any part nor any information contained in it may
 * be disclosed or furnished to others without the prior written consent of:
 *         Compunetix Incorporated
 *         2420 Mosside Blvd
 *         Monroeville, PA 15146
 *         http://www.compunetix.com
 *
 * Author:  lcheng
 */
import { NgModule } from "@angular/core";
import { BrowserModule } from "@angular/platform-browser";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";

import { ColorPickerModule } from "ngx-color-picker";
import { QuillModule } from "ngx-quill";

import { AccordionModule } from "ngx-bootstrap/accordion";
import { BsDatepickerModule } from "ngx-bootstrap/datepicker";

import { SharedModule } from "../shared/shared.module";

import { FormComponent } from "./form.component";
import { FieldComponent } from "./field.component";

import { FormService } from "./form.service";
import {TooltipModule} from "ngx-bootstrap/tooltip";

@NgModule({
    imports: [
        BrowserModule,
        FormsModule,
        ReactiveFormsModule,
        AccordionModule.forRoot(),
        ColorPickerModule,
        SharedModule,
        BsDatepickerModule.forRoot(),
        QuillModule,
        TooltipModule
    ],
  declarations: [FormComponent, FieldComponent],
  providers: [FormService],
  exports: [FormComponent]
})
export class FormModule {}
